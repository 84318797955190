import { Location } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';

import { ButtonComponent } from '~/app/shared/components/elements/buttons/button/button.component';
import { H2Component } from '~/app/shared/components/elements/h2/h2.component';

@Component({
    templateUrl: './error.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./error.component.scss'],
    standalone: true,
    imports: [
        H2Component,
        ButtonComponent,
        TranslocoPipe,
    ],
})
export class ErrorComponent {
    public errorCode: string = this.activeRoute.snapshot.data?.errorCode as string ?? '404';

    public constructor(
        private location: Location,
        private router: Router,
        private activeRoute: ActivatedRoute,
    ) {}

    public goBack() {
        this.location.back();
    }

    public goShareList() {

        void this.router.navigateByUrl('/shares/list');
    }
}
